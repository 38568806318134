import fetchApi from './config'
import { transformObjToUrlQuery } from '../utils/common'

const PORTAL_URL = process.env.REACT_APP_PORTAL_URL_API

export const createStore = async (data) => {
  return await fetchApi(`/stores`, data, 'post', {}, PORTAL_URL)
}

export const updateStore = async (id, data) => {
  return await fetchApi(`/stores/${id}`, data, 'put', {}, PORTAL_URL)
}

export const deleteStore = async (id) => {
  return await fetchApi(`/stores/${id}`, {}, 'delete', {}, PORTAL_URL)
}

export const getListGroup = async () => {
  return await fetchApi(`/admin/groups`, {}, 'get', {}, PORTAL_URL)
}

export const getGroupDetail = async (id, filter) => {
  return await fetchApi(`/admin/groups/${id}${transformObjToUrlQuery(filter)}`, {}, 'get', {}, PORTAL_URL)
}

export const getListService = async () => {
  return await fetchApi(`/admin/services`, {}, 'get', {}, PORTAL_URL)
}

export const createGroup = (data) => {
  return fetchApi(`/admin/groups`, data, 'post', {}, PORTAL_URL)
}

export const editGroup = (id, data) => {
  return fetchApi(`/admin/groups/${id}`, data, 'put', {}, PORTAL_URL)
}

export const addUserToGroup = (data) => {
  return fetchApi(`/admin/groups/user`, data, 'post', {}, PORTAL_URL)
}

export const removeUserGroup = (data) => {
  return fetchApi(`/admin/groups/remove-user`, data, 'post', {}, PORTAL_URL)
}

export const deleteGroup = (id) => {
  return fetchApi(`/admin/groups/${id}`, {}, 'delete', {}, PORTAL_URL)
}

export const enableService = (id) => {
  return fetchApi(`/admin/services/${id}/enable`, {}, 'put', {}, PORTAL_URL)
}

export const disableService = (id) => {
  return fetchApi(`/admin/services/${id}/disable`, {}, 'put', {}, PORTAL_URL)
}

export const createService = (data) => {
  return fetchApi(`/admin/services`, data, 'post', {}, PORTAL_URL)
}

export const updateService = (data, id) => {
  return fetchApi(`/admin/services/${id}`, data, 'put', {}, PORTAL_URL)
}

export const detailService = (id) => {
  return fetchApi(`/admin/services/${id}`, {}, 'get', {}, PORTAL_URL)
}

export const adminGetAllUserPortal = async (filter) => {
  return await fetchApi(`/users${transformObjToUrlQuery(filter)}`, {}, 'get', {}, PORTAL_URL)
}

export const getListRole = async () => {
  return await fetchApi(`/v1/group-permissions?limit=50`, {}, 'get', {}, PORTAL_URL)
}

export const getRoleDetail = async (id, filter) => {
  return await fetchApi(`/v1/group-permissions/${id}${transformObjToUrlQuery(filter)}`, {}, 'get', {}, PORTAL_URL)
}

export const getModuleAndService = async () => {
  return await fetchApi(`/v1/group-permissions/modules`, {}, 'get', {}, PORTAL_URL)
}

export const getUserInRole = async (id, filter) => {
  return await fetchApi(`/v1/group-permissions/${id}/users${transformObjToUrlQuery(filter)}`, {}, 'get', {}, PORTAL_URL)
}

export const updateRole = async (id, data) => {
  return await fetchApi(`/v1/group-permissions/${id}/`, data, 'patch', {}, PORTAL_URL)
}

export const addUserToRole = (id, data) => {
  return fetchApi(`/v1/group-permissions/${id}/users`, data, 'post', {}, PORTAL_URL)
}

export const deleteRole = (id) => {
  return fetchApi(`/v1/group-permissions/${id}`, {}, 'delete', {}, PORTAL_URL)
}

export const deleteUserFromRole = (id, data) => {
  return fetchApi(`/v1/group-permissions/${id}/users`, data, 'delete', {}, PORTAL_URL)
}

export const downloadFileIos = () => {
  return fetchApi(`/users/get-ios-link`, {}, 'get', {}, PORTAL_URL)
}

export const logout = () => {
  return fetchApi(`/logout`, {}, 'post', {}, PORTAL_URL)
}

export const addNewRole = async (data) => {
  return await fetchApi(`/v1/group-permissions`, data, 'post', {}, PORTAL_URL)
}
